<template>
<div>
    <!-- <button v-if="!show" class="badge btn-prime btn-xs" @click="showtable=!showtable">Active Complaint</button> -->
    <h5 class="text-center">Order Conversation</h5>
    <table class="table table-bordered table-font" style="margin-bottom:0;color:#279d00;font-size:13px!important;">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Conversation</th>
                <th>Entered BY</th>
                <th>Status</th>
                <th>Remark</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,index) in list" v-bind:key="item.id">
                <td>{{ index+1 }}</td>
                <!-- <td>{{ requestmethod(item) }}</td> -->
                <td>{{ item.detail }}</td>
                <!-- <td><span>{{item.followby!=null?item.followby.name:''}}</span></td> -->
                <td>{{ item.enteredby!=null?item.enteredby.name:''}}<br>
                    {{ moment(item.created_at).format("DD-MM-YYYY") }} {{moment(item.created_at).format('hh:mm:ss A')}}</td>
                <!-- <td>{{ moment(item.scheduledate).format("DD-MM-YYYY")}}</td> -->
                <td>{{ ordermethod(item)}}</td>
                <td>{{ getfollowedid(item) }}</td>
                <!-- <td>{{ leadbymethod(item)}}</td> -->
                <td>{{ item.leadremark}}</td>
            </tr>
        </tbody>
    </table>
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#d0e5cc"> 
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-2">
                           
                            <!-- <horizontal-stepper :steps="demoSteps" @completed-step="completeStep"
                                    @active-step="isStepActive" @stepper-finished="alert"
                            >   
                                           
                            </horizontal-stepper> -->
                            <div class="col-md-12 flex-between-row p-20 bg-white">
                                <div class="steps-wrapper">
                                    <div class="step">
                                        <div class="circle">
                                            <i class='bx bx-lock-open icon-success' v-if="step1success"></i>
                                            <i class='bx bx-lock-alt icon-i' v-if="step1success!=true && !isdanger"></i>
                                            <i class="bx bx-lock-alt icon-i icon-danger" v-if="isdanger"></i>
                                        </div>
                                        <div class="step-title">
                                            <h4>Approve/Rejected</h4>
                                        </div>
                                    </div>
                                    <hr class="line1">
                                    <div class="step" >
                                        <div class="circle">
                                            <i class='bx bxs-cart-download icon-i' :class="step2success==true?'icon-success':''"></i>
                                            <!-- <i class="bx bxs-cart-download icon-i icon-danger" v-if="isdanger"></i> -->
                                        </div>
                                        <div class="step-title">
                                            <h4>Order Dispatched</h4>
                                        </div>
                                    </div>
                                    <hr class="line2">
                                    <div class="step" >
                                        <div class="circle">
                                            <i class='bx bx-walk icon-i' :class="step3success==true?'icon-success':''"></i>
                                            <!-- <i class="bx bx-walk icon-i icon-danger" v-if="isdanger"></i> -->
                                        </div>
                                        <div class="step-title">
                                            <h4>On the way</h4>
                                        </div>
                                    </div>
                                    <hr class="line3">
                                    <div class="step">
                                        <div class="circle">
                                            <i class='bx bx-package icon-i' :class="step4success==true?'icon-success':''"></i>
                                            <!-- <i class="bx bx-package icon-i icon-danger" v-if="isdanger"></i> -->
                                        </div>
                                        <div class="step-title">
                                            <h4>Delivered/Rejected</h4>
                                        </div>
                                    </div>
                                </div>
                            
                                <step-one v-if="orderview==1"></step-one>
                                <step-two v-if="orderview==2"></step-two>
                                <step-three v-if="orderview==3"></step-three>
                                <step-four v-if="orderview==4"></step-four>
                            </div>
                            <div class="text-right col-md-12 mt-10" v-if="loginusercheck(173)">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                                            Conversation<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-6 col-md-9">
                                            <textarea class="form-control form-cascade-control input-small" v-model="detail" cols="20"
                                                id="CUSTHINT" name="CUSTHINT" rows="2" style="height:40px;"></textarea>
                                        </div>
                                        <button class="btn btn-danger" @click="dispose()">Dispose</button> 
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
// import { required } from 'vuelidate/lib/validators'
// import HorizontalStepper from 'vue-stepper';
import Constants from '../../components/utilities/Constants.vue';
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue';
import StepFour from './StepFour.vue';
export default {
    mixins:[Constants],
    props:['item','show'],
    components:{StepOne,StepTwo,StepThree,StepFour},
    data(){
        return {
            id:0,subject:'',detail:'',scheduledate:'',followedid:0,
            list:[],
            moment:moment,
            requesttype:4,
            leadtype:0,
            // shownewlead:false,
            newleadremark:'',
            showtable:false,
            showdate:true,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            status:'',
            orderview:0,
            step1success:false,
            step2success:false,
            step3success:false,
            step4success:false,
            isdanger:false
        }
    },
    mounted(){
        this.refresh();
    },
    computed:{
        ...mapGetters([
		   'loggedinuser','employees','orderconversation'
	    ]),
    },
    methods:{
        parentrefresh(){
            this.$parent.visitcomments=false
            this.$parent.refresh();
            
        },
        refresh(){
            let param = {leadid:this.item.id}
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/leads/customerconversation/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
            switch (this.item.status) {
                case 0:
                    console.log("enter in status 0")
                    if(this.loginusercheck(174)){
                        console.log("enter in status logged 0")
                        this.orderview=1
                        this.isdanger=false
                    }else{
                        this.orderview=0
                    }
                    break;
                case 1: //Approve
                    if(this.loginusercheck(175)){
                        this.orderview=2
                        
                    }else{
                        this.orderview=0
                    }
                    this.step1success=true
                    this.isdanger=false
                    break;
                case 2: //Reject
                    this.orderview=0
                    this.step1success=false
                    this.step2success=false
                    this.step3success=false
                    this.step4success=false
                    this.isdanger=true
                    break;
                case 3: // Dispatched
                    if(this.loginusercheck(176)){
                        this.orderview=3
                        
                    }else{
                        this.orderview=0
                    }
                    this.step1success=true
                    this.step2success=true
                    this.isdanger=false
                    break;
                case 4: //On the Way
                    if(this.loginusercheck(177)){
                        this.orderview=4
                        
                    }else{
                        this.orderview=0
                    }
                    this.step1success=true
                    this.step2success=true
                    this.step3success=true
                    break;
                case 5: //Order Delivered
                    
                    this.step1success=true
                    this.step2success=true
                    this.step3success=true
                    this.step4success=true
                    break;
                case 6: //Order Rejected
                    this.orderview=0
                    this.isdanger=true
                    break;
                default:
                    break;
            }
        },
        processDetailResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.list=data
        },
        submit(){
            let param = {leadid:this.item.id,detail:this.orderconversation?.detail,
                requesttype:this.requesttype,
                status:this.orderconversation?.status}

                this.$http.post('api/leads/customerconversation',param)
                .then((response) => this.processDetail(response.data))
                .catch((err) => {
                    console.log('', err)
                });
        },
        processDetail(){
            this.$parent.visitcomments = false
            let param={id:this.item.id,column:"status",value:this.status}
            this.$http.post('api/leads/updatefield',param)
                .then((response) =>this.processUpdateResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            
            this.$parent.refresh();
        },
        processLeadResponse(){
            this.$store.commit('assignloadingstatus',0)
            this.$parent.refresh();
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        ordermethod(item){
            let type="";
            switch (item.status) {
                case 0:
                    type= "Pending Order";
                    break;
                case 1:
                    type= "Approved";
                    break;
                case 2:
                    type= "Rejected";
                    break;
                case 3:
                    type= "Order Dispatch";
                    break;
                case 4:
                    type= "On the Way";
                    break;
                case 5:
                    type= "Order Delivered";
                    break;
                case 6:
                    type= "Order Rejected";
                    break;
                default:
                    break;
            }
            return type;
        },
        disposeoff(){
            if(this.loginusercheck(171)){
                if(confirm("Do You Really Want To Dispose")==true){
                    let param = {leadid:this.item.id,detail:this.detail,scheduledate:this.scheduledate,
                        requesttype:this.requesttype,leadremark:this.newleadremark,
                            followedid:this.followedid,status:this.status}
                    this.$http.post('api/leads/conversation/dispose',param)
                    .then((response) => this.processDetailDispose(response.data))
                    .catch((err) => {
                        console.log('', err)
                    });
                }
            }else{
                alert('You dont have right to Dispose')
            }
        },
        
        dispose(){
            if(confirm("Do you really want to disposed ")==true){
                let param = {leadid:this.item.id,detail:this.detail,
                    requesttype:4,
                    }
                this.$http.post('api/leads/conversation/dispose',param)
                .then((response) => this.processDetailDispose(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
        },
        processDetailDispose(){
            this.$parent.visitcomments = false
            this.$parent.refresh();
        },
        getfollowedid(item){
            if(item.followedid!=null){
                this.$store.commit('assignfollowedbydispatch',item.followedid);
            }
        }
        // getstep1class(){
        //     if(this.orderview>0){
        //         this.step1success=true
        //         return 'step-success'
        //     }
        //     else if(this.ord)
        // }
    }
}
</script>
<style>
.step{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
    cursor: pointer;
}
.circle{
    margin-bottom: 1rem;
    padding: 0 1rem;
    background-color: #fff;
}
.icon-i{
    background-color: #3383c8;
    color: #fff;
    border-radius: 100rem;
    padding: 1rem;
    font-size: 25px;
}
.icon-success{
    background-color: #00b10f;
    color: #fff;
    border-radius: 100rem;
    padding: 1rem;
    font-size: 25px;
}
.icon-danger{
    background-color: #b10000;
    color: #fff;
    border-radius: 100rem;
    padding: 1rem;
    font-size: 25px;
}
.step-title{
    position: absolute;
    top: 90%;
    width: 100%;
}
.steps-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 95%;
    left: 0;
    padding: 2% 4%;
    margin-bottom: 20px;
}
.line1{
    color: black;
    width: 15%;
    position: absolute;
    margin-left: 15%;
    margin-bottom: 3%;
    border: 1px solid #979797;
}
.line2{
    color: black;
    width: 15%;
    position: absolute;
    margin-left: 39%;
    margin-bottom: 3%;
    border: 1px solid #979797;
}
.line3{
    color: black;
    width: 15%;
    position: absolute;
    margin-left: 61%;
    margin-bottom: 3%;
    border: 1px solid #979797;
}
</style>