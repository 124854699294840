<template>
    <!-- <div style="padding: 2rem 3rem; text-align: left;">
       <div class="field">
           <label class="label">Username</label>
           <div class="control">
               <input :class="['input', ($v.form.username.$error) ? 'is-danger' : '']" type="text" placeholder="Text input"
                      v-model="form.username">
           </div>
           <p v-if="$v.form.username.$error" class="help is-danger">This username is invalid</p>
       </div>
       <div class="field">
           <label class="label">Email</label>
           <div class="control">
               <input :class="['input', ($v.form.demoEmail.$error) ? 'is-danger' : '']"  type="text" placeholder="Email input" v-model="form.demoEmail">
           </div>
           <p v-if="$v.form.demoEmail.$error" class="help is-danger">This email is invalid</p>
       </div>
       <div class="field">
           <label class="label">Message</label>
           <div class="control">
               <textarea :class="['textarea', ($v.form.message.$error) ? 'is-danger' : '']"  placeholder="Textarea" v-model="form.message"></textarea>
           </div>
       </div>
   </div> -->
   <div class="col-md-12" v-if="loginusercheck(175)">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#d0e5cc">
                <div class="form-horizontal cascde-forms">
                    <div class="form-group row" >
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Conversation<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="detail" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                            <p v-if="$v.detail.$error" class="help is-danger">Conversation is Required</p>
                        </div>
                    </div>
                    <div class="form-group row" >
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Status<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="status" class="form-control form-cascade-control input-small" @change="checkreject()">
                                <option value="3">Dispatched Order</option>
                                <option value="2">Reject Order</option>
                            </select>
                        </div>
                         
                    </div>
                    <div class="form-group row" >
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Followed by<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small" >
                                <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                         
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3"></div>
                    <div class="col-md-5 flex-around-row">
                        <button type="button" v-if="orderconversation.status<3" class="btn btn-success" style="margin-top:0" @click="submit()">Submit</button>
                       <!-- <button type="button" class="btn btn-success" style="margin-top:0" @click="resolved()">Approve Order</button>
                        <button  type="button" class="btn btn-danger" style="margin-top:0" @click="resolved()">Make Pending</button>
                        <button  type="button" class="btn btn-danger" @click="disposeoff()">Delivered</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
   props: ['clickedNext', 'currentStep'],
   mixins: [validationMixin,Constants],
   data() {
       return {
            status: 3,
            detail: '',
            shownext:false,
            followedid:''
       }
   },
   validations: {
        status: {
            required
        },
        followedid: {
            required
        },
    
        detail: {
            required
        }
   },
   computed:{
        ...mapGetters([
		   'loggedinuser','employees','orderconversation','followedbydispatch'
	    ]),
    },
   watch: {
       $v: {
           handler: function (val) {
               if(!val.$invalid || this.orderconversation.status>=1) {
                   this.$emit('can-continue', {value: true});
               } else {
                   this.$emit('can-continue', {value: false});
               }
           },
           deep: true
       },
        clickedNext(val) {
            console.log("clicked next")
           if(val === true) {

               this.$v.$touch();
           }
       }
    },
    mounted() {
        if(!this.$v.$invalid || this.orderconversation.status<=3) {
            this.$emit('can-continue', {value: true});
        } else {
            this.$emit('can-continue', {value: false});
        }
    },
    methods: {
        submit(){
            if(this.status!='' && this.detail!='' && this.followedid!=''){
                let param = {leadid:this.orderconversation.id,detail:this.detail,
                requesttype:4,status:this.status,followedid:this.followedid }

                this.$http.post('api/leads/customerconversation',param)
                    .then((response) => this.processDetail(response.data))
                    .catch((err) => {
                        console.log('', err)
                });
            }
        },
        processDetail(data){
            // this.$parent.visitcomments = false
            this.$store.commit('assignfollowedbydispatch',data.followedid);
            this.shownext=true
            let param={id:this.orderconversation.id,column:"status",value:this.status}
            this.$http.post('api/leads/updatefield',param)
                .then((response) =>this.processUpdateResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
        },
        processUpdateResponse(){
            // window.location.reload()
            this.$parent.parentrefresh()
        },
        checkreject(){
            if(this.status==2){
                if(confirm("Do you want to reject this order")){
                    alert("Order Set to Reject Successfully , Submit to Reject the order")
                }
            }
        }
    },
}
</script>